// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
    document.getElementById("to-top-desktop").style.display = "block";
    document.querySelector(".navbar-cta").classList.remove("hide");

  } else {
    document.getElementById("to-top-desktop").style.display = "none";
    document.querySelector(".navbar-cta").classList.add("hide");

  }
}

const navbar = document.querySelector('.navbar')

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  // window.scrollTo({
  //   top: 0,
  //   behavior: 'smooth', })

  navbar.scrollIntoView({behavior: 'smooth'})
}

const topButton = document.getElementById("to-top-desktop");

topButton.addEventListener("click", function(e) {
  topFunction();
})



